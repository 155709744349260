<template>
  <b-card no-body class="card-study-progress">
    <b-card-header>
      <div>
        <b-card-title>Progress Belajar</b-card-title>
        <b-card-text class="font-small-2 text-muted">
          Berikut merupakan progress belajar siswa
        </b-card-text>
      </div>
    </b-card-header>

    <!-- body -->
    <b-card-body>
      <div
        v-for="subject in studyProgressData"
        :key="subject.subjectImg"
        class="subject-states"
      >
        <b-media no-body class="w-40">
          <b-media-aside class="mr-1">
            <b-avatar variant="light-primary" icon="">
              <i :class="subject.subjectImg" class="font-medium-2 ti" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="align-self-center my-auto">{{ subject.name }}</h6>
            <span class="font-small-2 text-muted">
              {{ subject.task }}
            </span>
          </b-media-body>
        </b-media>
        <div class="w-60">
          <span
            class="font-small-2 text-muted ml-50 w-20 float-right text-right"
          >
            {{ subject.percentage }}%
          </span>
          <div class="progress-wrapper w-60">
            <b-progress
              :value="subject.percentage"
              max="100"
              variant="primary"
              height="8px"
            />
          </div>
        </div>
      </div>
    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>
import {
  BAvatar,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
  BDropdown,
  BDropdownItem,
  BProgress,
} from 'bootstrap-vue'

// import { $themeColors } from '@themeConfig'
// const $trackBgColor = '#e9ecef'

export default {
  components: {
    BAvatar,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BDropdown,
    BDropdownItem,
    BProgress,
  },
  props: {
    studyProgressData: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="scss">
.card-study-progress {
  .subject-states {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    align-items: center;
  }
}

.progress-wrapper {
  width: 50%;
  float: right;
  align-self: start;
  margin-top: 5px;
}

.card-study-progress {
  // height: 350px;
}
</style>
