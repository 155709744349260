<template>
  <b-card no-body class="card-activity">
    <b-card-header>
      <div>
        <b-card-title>
          <i class="ti ti-list-details mr-1"></i>
          Aktivitas
        </b-card-title>
      </div>
    </b-card-header>

    <!-- body -->
    <b-card-body>
      <app-timeline>
        <app-timeline-item
          v-for="activity in activityList"
          :key="activity.id"
          class="pb-0"
          variant="danger"
        >
          <div
            class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0"
          >
            <h6>{{ activity.title }}</h6>
            <small class="timeline-item-time text-nowrap text-muted ml-1">{{
              activity.duration
            }}</small>
          </div>
          <p>{{ activity.subtitle }}</p>
        </app-timeline-item>
      </app-timeline>
    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>
import {
  BAvatar,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
  BDropdown,
  BDropdownItem,
  BCardText,
  BImg,
} from 'bootstrap-vue'

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

// import { $themeColors } from '@themeConfig'
// const $trackBgColor = '#e9ecef'

export default {
  components: {
    BAvatar,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BDropdown,
    BDropdownItem,
    AppTimeline,
    AppTimelineItem,
    BCardText,
    BImg,
  },
  data() {
    return {
      activityList: [
        {
          id: 'task-id-1',
          title: 'Tugas Geografi',
          subtitle: 'Batas Pengumpulan Jam 20:00 WIB ',
          duration: 'Today',
        },
        {
          id: 'task-id-2',
          title: 'Tugas Matematika',
          subtitle: 'Batas Pengumpulan Jam 18:00 WIB ',
          duration: 'Today',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.card-activity .card-header {
  min-height: 84px;
}

.progress-wrapper {
  width: 50%;
  float: right;
  align-self: start;
  margin-top: 5px;
}

.w-20 {
  width: 20%;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

.w-80 {
  width: 80%;
}
</style>
