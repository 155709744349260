<template>
  <b-card no-body class="card-subject-states">
    <b-card-header>
      <div>
        <b-card-title>Jadwal Hari Ini</b-card-title>
      </div>
    </b-card-header>

    <b-card-body v-if="subjectData.length == 0">
      <div class="text-center pt-2">
        <b-img class="illustration-small" fluid :src="emptyState" />
        <p class="mb-2">
          Anda tidak mempunyai jadwal hari ini
        </p>
        <b-button
          variant="primary"
          class="mb-1 btn-sm-block"
          :to="{
            name: checkAuthorizeRole('classroom'),
          }"
        >
          Gabung Kelas Baru
        </b-button>
      </div>
    </b-card-body>

    <!-- body -->
    <b-card-body v-else>
      <div
        v-for="subject in subjectData"
        :key="subject.subjectImg"
        class="subject-states"
      >
        <b-media no-body class="w-40">
          <b-media-aside class="mr-1">
            <b-avatar variant="light-primary" icon="">
              <i :class="subject.subjectImg" class="font-medium-2 ti" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="align-self-center my-auto mt-2">{{ subject.name }}</h6>
            <span class="font-small-2 text-muted">
              {{ subject.task }}
            </span>
          </b-media-body>
        </b-media>
      </div>
    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>
import {
  BAvatar,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
  BDropdown,
  BDropdownItem,
  BProgress,
  BImg,
  BButton,
} from 'bootstrap-vue'

import client from '@/libs/http/axios-config'
import { checkAuthorizeRole } from '@/auth/utils'

export default {
  components: {
    BAvatar,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BDropdown,
    BDropdownItem,
    BProgress,
    BImg,
    BButton,
  },
  props: {
    subjectData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      emptyState: require('@/assets/images/illustration/empty-schedule-state.svg'),
    }
  },
  methods: {
    checkAuthorizeRole,
  },
}
</script>

<style lang="scss">
.card-subject-states {
  .subject-states {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    align-items: center;
  }
}

.progress-wrapper {
  width: 50%;
  float: right;
  align-self: start;
  margin-top: 5px;
}
</style>
