<template>
  <div>
    <div id="dashboard" v-if="!isLoading">
      <!-- News Berita -->
      <b-row class="mb-2">
        <!-- <b-col lg="8">
          <b-carousel
            v-if="newsImages.length > 0"
            id="carousel-caption"
            controls
            indicators
          >
            <b-carousel-slide
              v-for="item in newsImages"
              :key="item.id"
              :caption-html="
                `
                <span class='text-white'>
                  ${item.title}
                </span> </br>
                <a href='/student/news/${item.id}' class='mt-1 btn btn-primary' target='_self'>Detail Berita</a>`
              "
              class="card-img-overlay news-slide-content"
              :img-src="item.image"
            />
          </b-carousel>

          <div v-if="newsImages.length === 0" class="text-center mt-4">
            <b-spinner variant="primary" class="mr-1" />
            <p class="mt-1">
              Memuat Berita
            </p>
          </div>
        </b-col> -->
        <b-col lg="6" sm="12">
          <study-progress
            :study-progress-data="dashboardData.studyProgressData"
          />
        </b-col>
        <b-col lg="6" sm="12">
          <simple-schedule :subject-data="dashboardData.simpleSchedule" />
        </b-col>
      </b-row>

      <!-- Activity Card -->
      <!-- <b-row class="">
        <b-col lg="4" sm="12">
          <study-progress
            :study-progress-data="dashboardData.studyProgressData"
          />
        </b-col>
        <b-col lg="4" sm="12">
          <simple-schedule />
        </b-col>
        <b-col lg="4" sm="12">
          <activity-progress />
        </b-col>
      </b-row> -->
    </div>

    <div v-if="isLoading" class="d-flex justify-content-center vertical-center">
      <div class="text-center mt-4">
        <b-spinner variant="primary" class="mr-1" />
        <p class="mt-1">
          Memuat Dashboard
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// * Components
import { BRow, BCol, BCarousel, BCarouselSlide, BSpinner } from 'bootstrap-vue'
import StudyProgress from './components/StudyProgress.vue'
import SimpleSchedule from './components/SimpleSchedule.vue'
import ActivityProgress from './components/ActivityProgress.vue'

// * Utils
import client from '@/libs/http/axios-config'
import { ref, onMounted } from '@vue/composition-api'

export default {
  components: {
    BRow,
    BCol,
    BCarousel,
    BCarouselSlide,
    StudyProgress,
    ActivityProgress,
    SimpleSchedule,
    BSpinner,
  },
  data() {
    return {
      isLoading: true,
      dashboardData: {
        studyProgressData: [],
        activityProgress: [],
        simpleSchedule: [],
      },
      newsImages: [],
    }
  },
  async mounted() {
    await client
      .get('/students/dashboard')
      .then((response) => {
        this.dashboardData.studyProgressData = response.data.data.classrooms.map(
          (classroom) => {
            const progress = classroom.tasks.filter(
              (task) => task.submission_students_count === 1
            ).length

            return {
              subjectImg: classroom.logo,
              name: classroom.name,
              percentage: Math.round(
                (classroom.tasks.length === 0
                  ? 0
                  : progress / classroom.tasks_count) * 100
              ),
              task: `${classroom.tasks_count} Tugas`,
            }
          }
        )

        this.dashboardData.simpleSchedule = response.data.data.schedule.map(
          (schedule) => {
            return {
              subjectImg: schedule.logo,
              name: schedule.name,
              task: `${schedule.tasks_count} Tugas`,
            }
          }
        )
      })
      .catch((error) => {
        console.error(error)
      })

    await client
      .get('/news?status=published')
      .then((response) => {
        this.newsImages = response.data.data

        this.isLoading = false
      })
      .catch((error) => {
        console.error(error)
      })
  },
}
</script>

<style lang="scss">
.card-img-overlay {
  padding: 0 !important;
}

.news-slide-content {
  border-radius: 0.428rem !important;

  img {
    height: 350px;
    border-radius: 0.428rem !important;
    object-fit: cover;
  }
}
</style>
